

export type TestResultResponse = {
  test_id: string;
  result_id: string;
  organization: string;
  test_type: string;
  hcp_name: string;
  hcp_email: string;
  device_id: string;
  lfd_lot: string | null;
  status: string;
  date_created: string;
};

// API helpers

// Get all test results

export type FilterTestResultsParams = {
  page_number: number;
  page_size: number;
  search_term?: string;
  date_from?: string;
  date_to?: string;
  statuses?: Array<string>;
};

export type FilterTestResultsResponse = {
  page_number: number;
  page_size: number;
  number_of_pages: number;
  results: Array<TestResultResponse>;
};

// Get specific test result

export type GetTestResultParams = {
  test_id: string;
  result_id: string;
};

export type FeedbackAnswer = {
  question_content: string;
  answer_content: string;
  question_title: string;
};

export type FeedbackSection = {
  section_label: string;
  answers: Array<FeedbackAnswer>;
};

export type FeedbackElement = FeedbackAnswer | FeedbackSection;

export function isFeedbackElementSection(obj: FeedbackElement): obj is FeedbackSection {
  return 'section_label' in obj;
}

export function isFeedbackElementAnswer(obj: FeedbackElement): obj is FeedbackAnswer {
  return 'question_content' in obj;
}

type Image = {
  order: number;
  s3_url: string;
}

export type GetTestResultResponse = {
  subject_data: {
    test_id: string;
    created_at: string;
    test_started_at: string;
    subject_email?: string;
    news_and_announcements: boolean;
    bmi: number;
    answers: Array<{
      question_content: string;
      answer_content: string;
      risk_factor: number | null;
      influences_biomarkers: boolean;
    }>;
    personal_answers: Array<{
      question_content: string;
      answer_content: string;
      risk_factor: number | null;
      question_title: string;
    }>;
    feedback_answers: Array<FeedbackElement>;
    nhs_number: string;
    ods_code: string;
  };
  results: {
    metadata: {
      device_platform: string;
      device_model: string;
      app_ver: string;
      algo_ver: string;
      lfd_lot: string | null;
      test_started_at: string;
      timer_started_at: string;
      test_submitted_at: string;
      image_captured_at: string;
      results_created_at: string;
    };
    hdl: number;
    hdl_a: number | null;
    hdl_b: number | null;
    hdl_l: number | null;
    hdl_raw: number | null;
    hdl_thold_exceeded: boolean;
    total_cholesterol: number;
    tc_a: number | null;
    tc_b: number | null;
    tc_l: number | null;
    tc_raw: number | null;
    tc_thold_exceeded: boolean;
    triglycerides: number;
    tg_a: number | null;
    tg_b: number | null;
    tg_l: number | null;
    tg_raw: number | null;
    tg_thold_exceeded: boolean;
    status: string;
    pdf_url: string;
    pdf_pass: string;
    pdf_name: string;
    non_hdl: number;
    non_hdl_thold_exceeded: boolean;
    ldl: number;
    ldl_thold_exceeded: boolean;
    total_hdl_ratio: number;
    tc_hdl_ratio_thold_exceeded: boolean;
    warning_msg: string | null;
    qrisk: {
      qrisk_score_10_year: string | null;
      published_qrisk_score_10_year: string | null;
      qrisk_score_healthy_person: string | null;
      published_qrisk_score_healthy_person: string | null;
      qrisk_healthy_heart_age: string | null;
      published_qrisk_healthy_heart_age: string | null;
      qrisk_status: string;
    };
    images: {
      'barcodes&rois'?: Image;
      color_corrected?: Image;
      final?: Image;
      input_cropped?: Image;
      shade_corrected?: Image;
      white_balanced?: Image;
      linearized?: Image;
      original?: Image;
      rois?: Image;
      sites_binary_mask?: Image;
      levels_adjusted?: Image;
    };
  };
  error_logs: {
    error_id: string;
    error_type: string;
    created_at: string;
    img_captured_at: string;
    timer_started_at: string;
    test_submitted_at: string;
    images: {
      original: {
        order: number;
        s3_url: string;
      };
    };
  }[];
  nhs_email_meta: {
    pdf_name: string;
    pdf_url: string;
    sent_at: string;
    status: string;
  }
};
