import React, { useState } from 'react';
import Colors from '../../../../theme/colors';
import { getEmailStatusColor, capitalizeWord } from '../../../../utilities/utils';
import { formatDateToLongFormat } from '../../../../utilities/dateTimeUtils';
import SectionHeader from '../SectionHeader/SectionHeader';
import {
  Circle, Container, ContentContainer, Label, Value, Row, Text,
  LabelWithStatus,
  ValueWithStatus,
} from './styles';
import EHRIntegrationsProps from '.';

function EHRIntegrations({ nhsEmailMeta }: EHRIntegrationsProps) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };

  return (
    <Container>
      <SectionHeader
        icon="envelope"
        title={`EHR Integrations ${nhsEmailMeta ? '' : ' (Unavailable)'}`}
        expandable={!!nhsEmailMeta}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      {expanded ? (
        <ContentContainer>
          <Row>
            <LabelWithStatus variant="BodyMediumBold" color={Colors.Text.Paragraph}>Email status</LabelWithStatus>
            <ValueWithStatus variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
              <Circle color={getEmailStatusColor(nhsEmailMeta?.status)} />
              {nhsEmailMeta && capitalizeWord(nhsEmailMeta?.status)}
            </ValueWithStatus>
          </Row>
          <Row>
            <Label variant="BodyMediumRegular" color={Colors.Text.Paragraph}>Sent</Label>
            <Value variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
              {formatDateToLongFormat(nhsEmailMeta?.sentAt)}
            </Value>
          </Row>
        </ContentContainer>
      ) : null}
    </Container>
  );
}

export default EHRIntegrations;
