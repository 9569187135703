import React, { useState } from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import { Container, ContentContainer } from './styles';
import AnswerShort from '../AnswerShort/AnswerShort';
import PersondalDataProps from '.';

function PersonalData({ personalAnswers }: PersondalDataProps) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };
  const formatValue = (label: string, value: any) => {
    if (label === 'Date of Birth') {
      return new Date(value).toLocaleDateString('en-GB');
    }
    return value;
  };
  return (
    <Container>
      <SectionHeader
        icon="accessibility"
        title="Personal Information"
        expandable
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      {expanded ? (
        <ContentContainer>
          {personalAnswers.map(({ label, value }) => (
            <AnswerShort key={label} label={label} value={value ? formatValue(label, value) : '-'} />
          ))}
        </ContentContainer>
      ) : null}
    </Container>
  );
}

export default PersonalData;
